.App {
  text-align: left;
  background-color: offwhite;
}

.App-header {
  background-color: darkgrey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input {
  padding: 10px;
  margin: 10px;
  width: 300px;
}

button {
  padding: 10px;
  margin: 10px;
  cursor: pointer;
}

/* Scroll bar */
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: slategrey;
  border-radius: 10px;
}

.searchInput {
  border-radius: 10px;
}

.searchBtn {
  cursor: pointer;
  border-radius: 10px;
}

.Content{
  background-color: lightgrey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;

}